/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import HeroImage from "../assets/svg/heroImageSeQuenX.svg";
import TextSlider from "./TextSlider";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t } = useTranslation();
  const textItems = t("heroSection.textItems", { returnObjects: true });
  return (
    <div className=" bg-gradient-to-r from-[#130B50] from-0% to-[#404D89] to-46%  py-2 px-4 text-white ">
      <div className="container mx-auto flex max-md:flex-col items-center justify-center">
        <div className="w-full md:w-[40%]">
          <h1 className="text-[2.1rem] md:text-[3.5vw] pt-4 font-semibold font-['Alegreya_Sans_SC']">
            {t("heroSection.welcome")}
          </h1>
          <p className="text-[1.1rem] md:text-[1.5vw] pt-4 pb-6 ">
            {t("heroSection.tagLine")}
          </p>

          <TextSlider items={textItems} interval={5000} />
        </div>
        <div className="w-full md:w-[60%]">
          <img src={HeroImage} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
