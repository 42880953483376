/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { Our_vision, Who_we_are } from "../assets/svg";
import { useTranslation } from "react-i18next";

const HomeDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#455590] py-4 px-4 text-left text-white">
      <div className="container mx-auto flex flex-col-reverse items-center  md:flex-row">
        <div className="w-full md:w-[50%]">
          <img
            src={Who_we_are}
            alt="Who_We_Logo"
            className="img-responsive w-[75%] mx-auto"
          />
        </div>
        <div className="w-full md:w-[50%]">
          <h1 className="md:text-[2.5rem] text-[2.1rem] font-['Alegreya_Sans_SC'] mb-2">
            {t("whoWeAre.title")}
          </h1>
          <p className="text-justify pt-4 pb-10">{t("whoWeAre.content")}</p>
          <a
            href="/about-us"
            className="text-lg text-white border-2 border-white px-6 py-3 rounded-md font-['Alegreya_Sans_SC'] hover:bg-white hover:text-[#455590] transition duration-300 ease-in-out "
          >
            {t("whoWeAre.viewMore")}
          </a>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center md:flex-row">
        <div className="w-full md:w-[50%]">
          <h1 className="md:text-[2.5rem] text-[2.1rem] font-['Alegreya_Sans_SC'] mb-2 max-md:mt-6">
            {t("ourVision.title")}
          </h1>
          <p className="text-justify pt-4 pb-10">{t("ourVision.content")}</p>
          <a
            href="/about-us"
            className="text-lg text-white border-2 border-white px-6 py-3 rounded-md mt-4 font-['Alegreya_Sans_SC'] hover:bg-white hover:text-[#455590] transition duration-300 ease-in-out"
          >
            {t("ourVision.viewMore")}
          </a>
        </div>
        <div className="w-full md:w-[50%] max-md:mt-6">
          <img
            src={Our_vision}
            alt="Our_Vision_Logo"
            className="img-responsive w-[75%] mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeDetails;
