/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { terms_of_use } from "../assets/svg";

const ServiceDetailItem = ({ title, description }) => (
  <li className="hover:text-white leading-8">
    {title}
    {description && (
      <p className="text-sm text-gray-400 font-normal">{description}</p>
    )}
  </li>
);

const TermsOfUseDetails = () => {
  const termsOfUseDetails = [
    {
      title:
        "This web site contains material including text, photographs and other images and sound, which is protected by copyright and/or other intellectual property rights. All copyright and other intellectual property rights in this material are either owned by SeQuenX B.V. or have been licensed to it by the owner(s) of those rights so that it can use this material as part of this web site.",
    },
    {
      title:
        'This web site also contains trade marks & logos, including the "SeQuenX" animated logo / trade marks and “Software Quality Engineering Excellence” tag line. All trade marks, & logos included on this web site belong to SeQuenX B.V. or have been licensed to it by the owner(s) of those trade marks for use on this web site.',
    },
  ];

  const youMayDetails = [
    {
      title: "access any part of the web site;",
    },
    {
      title:
        "print off one copy of any or all of the pages for your own personal reference.",
    },
  ];
  const youMayNotDetails = [
    {
      title:
        "copy (whether by printing off onto paper, storing on disk, downloading or in any other way), distribute (including distributing copies), broadcast, alter or tamper with in any way or otherwise use any material contained in the web site. These restrictions apply in relation to all or part of the material on the web site;",
    },
    {
      title:
        "remove any copyright, trade mark or other intellectual property notices contained in the original material from any material copied or printed off from the web site; without our express written consent.",
    },
  ];

  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full md:w-[50%]">
          <div className="text-justify my-auto">
            <p className="leading-8">
              Welcome to the SeQuenX.com website. This web site is owned and
              operated by SeQuenX B.V., a company registered in The Netherlands,
              whose registered office is at Office 6J5 | Vitruviusstraat 138,
              2314 CW, Leiden, The Netherlands.Visitors to this web site are
              bound by the following terms and conditions, please read these
              carefully before you continue.
            </p>
            <h1 className="text-xl font-bold my-4">Terms of Use</h1>
            <ul className="list-disc list-inside">
              {termsOfUseDetails.map((detail, index) => (
                <ServiceDetailItem
                  key={index}
                  title={detail.title}
                  description={detail.description}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <img
            src={terms_of_use}
            alt="Privacy Policy"
            className="img-responsive w-[60%] mx-auto"
          />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full">
          <div className="text-justify my-auto">
            <h1 className="text-xl font-bold my-4">You may</h1>
            <ul className="list-disc list-inside">
              {youMayDetails.map((detail, index) => (
                <ServiceDetailItem key={index} title={detail.title} />
              ))}
            </ul>
            <h1 className="text-xl font-bold my-4">You may not</h1>
            <ul className="list-disc list-inside">
              {youMayNotDetails.map((detail, index) => (
                <ServiceDetailItem key={index} title={detail.title} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUseDetails;
