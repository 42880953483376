/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useEffect } from "react";
import { SubHeader, GetInTouch, Branches, Footer } from "../components";

const ContactUs = () => {
  useEffect(() => {
    document.title = "SeQuenX - Contact Us";
  }, []);

  return (
    <>
      <SubHeader title={"Contact Us"} />
      <GetInTouch />
      <Branches />
      <Footer />
    </>
  );
};

export default ContactUs;
