/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  asml_logo,
  nl_logo,
  openchain_logo,
  complyvantage_logo,
  normeringarbeid_logo,
  hcl_logo,
  ingBank_logo,
  nxp_logo,
  shell_logo,
  philips_logo,
  comsats_logo,
  googlePartner_logo,
} from "../assets/svg";

const ClientsSlider = () => {
  const images = [
    {
      client_icon: complyvantage_logo,
      client_url: "https://complyvantage.com/",
    },
    {
      client_icon: openchain_logo,
      client_url:
        "https://www.openchainproject.org/featured/2021/01/13/sequenx-is-the-latest-openchain-partner",
    },
    {
      client_icon: normeringarbeid_logo,
      client_url:
        "https://www.normeringarbeid.nl/snakeurmerk/gecertificeerde-ondernemingen?q=SeQuenX&filters%5Bsector%5D=&filters%5Bkvk%5D=&filters%5Bnormen%5D=",
    },
    {
      client_icon: asml_logo,
      client_url: "https://www.asml.com/en",
    },
    {
      client_icon: nl_logo,
      client_url:
        "https://ind.nl/en/public-register-recognised-sponsors/public-register-regular-labour-and-highly-skilled-migrants",
    },
    {
      client_icon: ingBank_logo,
      client_url: "https://www.ing.com/Home.htm",
    },
    {
      client_icon: nxp_logo,
      client_url: "https://www.nxp.com/",
    },
    {
      client_icon: hcl_logo,
      client_url: "https://www.hcltech.com/",
    },
    {
      client_icon: shell_logo,
      client_url: "https://www.shell.nl/",
    },
    {
      client_icon: philips_logo,
      client_url: "https://www.philips.nl/",
    },
    {
      client_icon: comsats_logo,
      client_url:
        "https://comsats.org/comsats-and-dutch-it-company-sequenx-to-establish-technology-corridor-cntc/",
    },
    {
      client_icon: googlePartner_logo,
      client_url: "https://ads.google.com/home/partners/become-a-partner/",
    },
  ];
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const updateSlidesToShow = () => {
      // Set the number of slides to show based on the screen width
      if (window.innerWidth >= 1200) {
        setSlidesToShow(4);
      } else if (window.innerWidth >= 900) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 600) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(3);
      }
    };

    // Call the updateSlidesToShow function on initial render
    updateSlidesToShow();

    // Call the updateSlidesToShow function whenever the screen is resized
    window.addEventListener("resize", updateSlidesToShow);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className="py-2">
      <div className="py-2 px-4">
        <div className="container mx-auto flex items-center justify-center">
          <div className="max-w-[100%] mx-auto p-5 justify-center">
            <Slider {...sliderSettings}>
              {images.map((imageUrl, index) => (
                <div key={index} className="text-center px-4 justify-center">
                  <a href={imageUrl.client_url}>
                    <img
                      className="object-fit md:h-16 h-12 justify-center mx-auto"
                      src={imageUrl.client_icon}
                      alt={`Slide ${index}`}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsSlider;
