/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState } from "react";
import { contact_us } from "../assets/svg";
import { useTranslation } from "react-i18next";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
  });
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can perform actions with the form data
    console.log("Form data:", formData);
    // You can send the data to an API, perform validation, etc.
    // For now, let's just clear the form fields
    setFormData({
      name: "",
      email: "",
      description: "",
    });
  };

  return (
    <div className="py-12 px-4">
      <div className="container mx-auto flex flex-col items-center justify-center rounded-lg shadow-[0px_0px_20px_0px_#00000024] p-8 md:flex-row">
        <div className="w-full md:w-[50%]">
          <div className="text-justify mx-auto">
            <h1 className="text-xl font-bold text-[#455590] font-['Alegreya_Sans_SC'] mb-2">
              {t("contactUs.getInTouch.title")}
            </h1>
            <p>{t("contactUs.getInTouch.description")}</p>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <input
                  type="text"
                  id="name"
                  placeholder={t("contactUs.getInTouch.name")}
                  className="border bg-[#F5F5FF] rounded-lg p-4 mb-4"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  id="email"
                  placeholder={t("contactUs.getInTouch.email")}
                  className="border bg-[#F5F5FF] rounded-lg p-4 mb-4"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <textarea
                  id="description"
                  placeholder={t("contactUs.getInTouch.details")}
                  className="border bg-[#F5F5FF] rounded-lg p-4 mb-4 h-[10rem]"
                  required
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <button
                className="bg-[#455590] text-xl font-['Alegreya_Sans_SC'] text-white rounded-lg p-4 mt-4 w-full  hover:bg-white hover:text-[#455590] transition duration-300 ease-in-out hover:border-2 hover:border-[#455590]"
                type="submit"
              >
                {t("contactUs.getInTouch.ctaText")}
              </button>
            </form>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <img
            src={contact_us}
            alt="About us"
            className="img-responsive w-[75%] mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
