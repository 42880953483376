/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import AboutUsImg from "../assets/svg/about-us.svg";
import { useTranslation } from "react-i18next";

const AboutUsDetails = () => {
  const { t } = useTranslation();
  const aboutUsDescription = t("aboutUs.description", { returnObjects: true });
  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <h1 className="md:text-[2.5rem] text-[2.1rem] text-center font-['Alegreya_Sans_SC'] mb-2">
        {t("aboutUs.title")}
      </h1>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full md:w-[50%]">
          <img
            src={AboutUsImg}
            alt="About us"
            className="img-responsive w-[75%] mx-auto"
          />
        </div>
        <div className="w-full md:w-[50%]">
          <div className="text-justify mx-auto">
            <p>
              {aboutUsDescription[0]}
              <br />
              <br />
              {aboutUsDescription[1]}
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full ">
          {/* <h1 className="text-[3rem] text-left font-['Alegreya_Sans_SC'] mb-2">
            Our Vision
          </h1> */}
          <div className="text-justify pb-10">
            <p>
              {aboutUsDescription[2]}
              <br />
              <br />
              {aboutUsDescription[3]}
              <br />
              <br />
              {aboutUsDescription[4]}{" "}
              <a
                href="https://www.normeringarbeid.nl/snakeurmerk/gecertificeerde-ondernemingen?q=SeQuenX&filters%5Bsector%5D=&filters%5Bkvk%5D=&filters%5Bnormen%5D="
                rel="noopener noreferrer"
                target="_blank"
                className="text-[#2c2aa0] underline"
              >
                {aboutUsDescription[5]}
              </a>{" "}
              {aboutUsDescription[6]}{" "}
              <a
                href="https://ind.nl/en/search?s=SeQuenX"
                rel="noopener noreferrer"
                target="_blank"
                className="text-[#2c2aa0] underline"
              >
                {aboutUsDescription[7]}
              </a>{" "}
              {aboutUsDescription[8]}
            </p>
            <p className="mt-4">
              {aboutUsDescription[9]}{" "}
              <a
                href="https://www.openchainproject.org/featured/2021/01/13/sequenx-is-the-latest-openchain-partner"
                rel="noopener noreferrer"
                target="_blank"
                className="text-[#2c2aa0] underline"
              >
                {aboutUsDescription[10]}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsDetails;
