/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  SEB,
  SEW,
  SCTB,
  SCTW,
  OSS_CSB,
  OSS_CSW,
  QAB,
  QAW,
} from "../assets/svg";

const Services = () => {
  const { t } = useTranslation();

  // Memoize servicesData to prevent unnecessary re-creation
  const servicesData = useMemo(
    () => [
      {
        title: t("servicesSection.softwareDev"),
        description: t("servicesSection.softwareDevDesc"),
        icon_B: SEB,
        icon_W: SEW,
        hover: false,
        hrefLink: "/service/software-dev",
      },
      {
        title: t("servicesSection.softwareQuality"),
        description: t("servicesSection.softwareQualityDesc"),
        icon_B: QAB,
        icon_W: QAW,
        hover: false,
        hrefLink: "/service/software-quality-assurance",
      },
      {
        title: t("servicesSection.ossCompliance"),
        description: t("servicesSection.ossComplianceDesc"),
        icon_B: OSS_CSB,
        icon_W: OSS_CSW,
        hover: false,
        hrefLink: "/service/oss-compliance-and-security",
      },
      {
        title: t("servicesSection.consultancy"),
        description: t("servicesSection.consultancyDesc"),
        icon_B: SCTB,
        icon_W: SCTW,
        hover: false,
        hrefLink: "/service/software-consultancy-and-training",
      },
      // Add translations and data for other services
    ],
    [t]
  );

  const [services, setServices] = useState(servicesData);

  const handleMouseEnter = (index) => {
    setServices((prevServices) => {
      const updatedServices = [...prevServices];
      updatedServices[index].hover = true;
      return updatedServices;
    });
  };

  const handleMouseLeave = (index) => {
    setServices((prevServices) => {
      const updatedServices = [...prevServices];
      updatedServices[index].hover = false;
      return updatedServices;
    });
  };

  useEffect(() => {
    setServices(servicesData);
  }, [servicesData]);
  return (
    <div className="services-section py-8 px-4 text-center">
      <div className="container mx-auto">
        <h1 className="text-[#455590] md:text-[2.5rem]  text-[2.1rem] text-center font-['Alegreya_Sans_SC']">
          {t("servicesSection.heading")}
        </h1>
        <h1 className="text-[#455590] md:text-[2.5rem] text-[2.1rem] text-center font-['Alegreya_Sans_SC'] mb-8">
          {t("servicesSection.subHeading")}
        </h1>

        <Grid container spacing={2} justifyContent="left">
          {services.map((service, index) => (
            <Grid key={index} item xs={12} md={6} lg={4} align="left">
              <div
                onClick={() => (window.location.href = service.hrefLink)}
                className="flex flex-col h-full  bg-white hover:text-white  hover:bg-[#455590] hover:bg-opacity-70 rounded p-4 hover:border-0 border-2 border-black transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div className="service-icon flex justify-center items-center">
                  {service.hover ? (
                    <img
                      src={service.icon_W}
                      className="md:h-[8rem] h-[6rem]"
                      alt=""
                    />
                  ) : (
                    <img
                      src={service.icon_B}
                      className="md:h-[8rem] h-[6rem]"
                      alt=""
                    />
                  )}
                </div>
                <h3
                  className={`md:text-[1.8rem] text-[1.6rem] text-center font-['Alegreya_Sans_SC'] mb-2 mx-1 ${
                    service.hover ? "text-white" : "text-[#455590]"
                  }`}
                >
                  {service.title}
                </h3>
                <p className="text-justify mx-2">{service.description}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Services;
