/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem("cookieAccepted");
    if (!isAccepted) {
      setIsBannerVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", "true");
    setIsBannerVisible(false);
  };

  const handleClose = () => {
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0 mb-4 mr-4 w-64">
      <div className="bg-white rounded-lg shadow-lg p-4">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <img
              src="https://www.svgrepo.com/show/401340/cookie.svg"
              alt="Cookie"
              className="h-6 w-6 mr-2"
            />
            <span className="text-gray-700 font-bold text-sm">
              Cookie Policy
            </span>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <p className="text-gray-600 text-sm">
          We use cookies to enhance your experience. By continuing to visit this
          site, you agree to our use of cookies. Learn more in our{" "}
          <a className="text-blue-700" href="/policies/cookie-policy">
            cookie policy
          </a>
        </p>
        <button
          onClick={handleAccept}
          className="mt-4 bg-[#455590] hover:bg-[#313e6b] text-white font-bold py-2 px-4 rounded"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
