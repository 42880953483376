/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { cookie } from "../assets/svg";

const ServiceDetailItem = ({ title, description }) => (
  <li className="hover:text-white leading-8">
    {title}
    {description && (
      <p className="text-sm text-gray-400 font-normal">{description}</p>
    )}
  </li>
);

const CookiePolicyDetails = () => {
  const cookieTypesDetails = [
    {
      title:
        "Session cookies help you navigate through the website efficiently, keeping track of your progression from page to page so that you are not asked for information you have already provided during the current visit, or information needed to be able to complete a transaction. Session cookies are stored in temporary memory and erased when the web browser is closed.",
    },
    {
      title:
        "Persistent cookies on the other hand, store user preferences for current and successive visits. They are written on your device’s hard disk and are still valid when you restart your browser. We use persistent cookies, for example, to record your choice of language and country location and for Google Analytics.",
    },
  ];
  const cookieCategoriesedDetails = [
    {
      title:
        "Strictly necessary cookies: These cookies are essential to enable you to use the website effectively, such as when buying a product and / or service, and therefore cannot be turned off. Without these cookies, the services available to you on our website cannot be provided. These cookies do not gather information about you that could be used for marketing or remembering where you have been on the internet.",
    },
    {
      title:
        "Performance cookies: These cookies enable us to monitor and improve the performance of our website. For example, they allow us to count visits, identify traffic sources and see which parts of the site are most popular.",
    },
    {
      title:
        "Functionality cookies: These cookies allow our website to remember choices you make and provide enhanced features. For instance, we may be able to provide you with news or updates relevant to the services you use. They may also be used to provide services you have requested such as viewing a video or commenting on a blog. The information these cookies collect is usually anonymised.",
    },
  ];

  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full md:w-[50%]">
          <div className="text-justify my-auto">
            <p className="leading-8">
              We collect information from your visits to our websites and your
              use of our cloud services and our software products to help us
              gather statistics about usage and effectiveness, personalise your
              experience, tailor our interactions with you, and improve our
              products and services. We use “cookies” to do this.
            </p>
            <h1 className="text-xl font-bold my-4">
              What are cookies and why are cookies used?
            </h1>

            <p className="leading-8">
              Cookies are used by nearly all websites and do not harm your
              system. A cookie is a piece of data that a website can send to
              your browser, which may then be stored on your computer as a tag
              that identifies your computer. While cookies are often only used
              to measure website usage (such as number of visitors and duration
              of visit) and effectiveness (such as topics visitors are most
              interested in) and to allow for ease of navigation or use and, as
              such, are not associated with any personal information, they are
              also used at times to personalise a known visitor’s experience of
              a website by being associated with profile information or user
              preferences. Over time this information provides valuable insight
              to help improve the user experience.
            </p>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <img
            src={cookie}
            alt="Privacy Policy"
            className="img-responsive w-[60%] mx-auto"
          />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full">
          <div className="text-justify my-auto">
            <h1 className="text-xl font-bold my-4">
              Cookies are typically classed as session cookies or persistent
              cookies
            </h1>
            <ul className="list-disc list-inside">
              {cookieTypesDetails.map((detail, index) => (
                <ServiceDetailItem key={index} title={detail.title} />
              ))}
            </ul>
            <h1 className="text-xl font-bold my-4">
              Cookies can also be categorised as
            </h1>
            <ul className="list-disc list-inside">
              {cookieCategoriesedDetails.map((detail, index) => (
                <ServiceDetailItem key={index} title={detail.title} />
              ))}
            </ul>
            <p className="leading-8">
              If you want to check or change what types of cookies you accept,
              this can usually be altered within your browser settings. You can
              block cookies at any time by activating the setting on your
              browser that allows you to refuse the setting of all or some
              cookies. However, if you use your browser settings to block all
              cookies (including essential cookies) you may not be able to
              access all or parts of our site.
            </p>
            <h1 className="text-xl font-bold my-4">
              How to express privacy preferences regarding the use of cookies
            </h1>
            <p className="leading-8">
              When visiting our websites or online services, you may have the
              possibility to set your preferences regarding cookies and other
              similar technologies by using the options and tools made available
              to you by your web browser. If a cookie manager has been
              implemented by a SeQuenX Company, it will be displayed at your
              first visit to our website.
            </p>
            <p className="leading-8">
              While SeQuenX Company websites, online and cloud services at this
              time do not recognise automated browser signals regarding tracking
              mechanisms, such as “do not track” instructions, you can generally
              express your privacy preferences regarding the use of most cookies
              and similar technologies through your web browser. Look under the
              heading “Tools” (or similar heading) in your particular browser
              for information about controlling cookies. In most instances you
              can set your browser to notify you before you receive a cookie,
              giving you the option to decide whether to accept it or not. You
              can also generally set your browser to turn off cookies. Cookies
              in our software products can be turned off in the product itself.
              Since cookies allow you to take advantage of some of our websites’
              features or features of our software products and cloud services,
              we recommend that you leave them turned on. If you block, turn off
              or otherwise reject our cookies, some web pages may not display
              properly, or you will not be able to, for instance, use any
              website or cloud services that require you to sign in.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicyDetails;
