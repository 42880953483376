/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState } from "react";
import { compliance_security } from "../assets/svg";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// DetailItem Component
const DetailItem = ({ title, description, onMoreDetails }) => (
  <li className="pl-2 list-inside leading-8 hover:text-white">
    <span className="font-semibold">{title} </span>
    <span
      className="font-semibold underline hover:cursor-pointer"
      onClick={() => onMoreDetails(title, description)}
    >
      More details
    </span>
  </li>
);

// AlertDialog Component
const AlertDialog = ({ open, onClose, title, description }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontFamily: "Alegreya Sans SC",
          fontWeight: "bold",
          color: "#455590",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {title === "OSPO services and Security Vulnerabilities." ? (
            <div>
              <div className="mb-4">
                <h2 className="font-bold text-black">
                  {t("OSSCSS.responsibilities")}
                </h2>
                <p>{t("OSSCSS.OSPOServiceSecurityVuln.list")}</p>
              </div>
              <div className="mb-4">
                <h2 className="font-bold text-black">
                  {" "}
                  {t("OSSCSS.OSPOServiceSecurityVuln.list1")}
                </h2>
                <p>{t("OSSCSS.OSPOServiceSecurityVuln.list2")}</p>
                <ul className="list-disc pl-4">
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list3")}</li>
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list4")}</li>
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list5")}</li>
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list6")}</li>
                </ul>
                <p>{t("OSSCSS.OSPOServiceSecurityVuln.list7")}</p>
              </div>
              <div className="mb-4">
                <h2 className="font-bold text-black">
                  {t("OSSCSS.OSPOServiceSecurityVuln.list8")}
                </h2>
                <ul className="list-disc pl-4">
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list9")}</li>
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list10")}</li>
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list11")}</li>
                  <li> {t("OSSCSS.OSPOServiceSecurityVuln.list12")}</li>
                </ul>
              </div>
            </div>
          ) : title === "OSS Management Training & Coaching." ? (
            <div className="mb-4">
              <h2 className="font-bold text-black">
                {t("OSSCSS.responsibilities")}
              </h2>
              <ul className="list-disc pl-4">
                <li>{t("OSSCSS.ossManagementTraining.list")}</li>
                <ul className="list-disc pl-4">
                  <li>{t("OSSCSS.ossManagementTraining.list1")}</li>
                  <li>{t("OSSCSS.ossManagementTraining.list2")}</li>
                  <li>{t("OSSCSS.ossManagementTraining.list3")}</li>
                </ul>

                <li> {t("OSSCSS.ossManagementTraining.list4")}</li>
                <li> {t("OSSCSS.ossManagementTraining.list5")}</li>
                <li> {t("OSSCSS.ossManagementTraining.list6")}</li>
              </ul>
            </div>
          ) : (
            <>
              <h2 className="font-bold text-black">
                {t("OSSCSS.responsibilities")}
              </h2>
              <ul className="list-disc">
                {description?.map((detail, index) => (
                  <li key={index} className="pl-2 list-inside leading-8">
                    {detail}
                  </li>
                ))}
              </ul>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

// OSSCSServiceDetails Component
const OSSCSServiceDetails = () => {
  const { t } = useTranslation();

  // Ensure that the translation function returns the expected array of objects
  const processDetails =
    t("OSSCSS.whatWeDo.details", { returnObjects: true }) || [];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState({
    title: "",
    description: [],
  });

  const handleMoreDetailsClick = (title, description) => {
    setSelectedDetail({ title, description });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full md:w-[50%] max-md:my-4">
          <img
            src={compliance_security}
            alt={t("OSSCSS.title")}
            className="img-responsive w-[60%] mx-auto"
          />
        </div>
        <div className="w-full md:w-[50%]">
          <div className="text-justify my-auto">
            <span className="font-bold">{t("OSSCSS.introduction.h1")}</span>
            {t("OSSCSS.introduction.paragraphs")}
            <br />
            {t("OSSCSS.introduction.paragraphs1")}
            <br />
            {t("OSSCSS.introduction.paragraphs2")}
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full">
          <div className="text-justify my-auto">
            <h1 className="text-2xl font-bold mb-4">
              {t("OSSCSS.whatWeDo.h1")}
            </h1>
            <ul className="list-disc">
              {processDetails.map((detail, index) => (
                <DetailItem
                  key={index}
                  title={detail.title}
                  description={detail.description}
                  onMoreDetails={handleMoreDetailsClick}
                />
              ))}
            </ul>
            <br />
            {t("OSSCSS.paragraphs")}
          </div>
        </div>
      </div>
      <AlertDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title={selectedDetail.title}
        description={selectedDetail.description}
      />
    </div>
  );
};

export default OSSCSServiceDetails;
