/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState, forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TeamDetailsDialogs = (details) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(details);

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          color: "#ffffff",
          borderColor: "#ffffff",
          borderRadius: "10px",
          padding: sessionStorage.getItem("language")
            ? "0.5rem 1rem"
            : "0.5rem 2rem",
          fontSize: "0.6rem",
          textAlign: "right",
          fontFamily: "Alegreya Sans SC, sans-serif",
          "&:hover": {
            color: "#ffffff",
            borderColor: "#ffffff",
          },
        }}
      >
        View Details
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
          <div className="w-full md:w-[20%]">
            <img
              src={details?.details?.icon}
              alt="Who_We_Logo"
              className="img-responsive md:w-[90%] w-[50%] mx-auto md:mt-6 mt-2 rounded-lg"
            />
          </div>
          <div className="w-full md:w-[80%]">
            <DialogTitle
              sx={{
                fontFamily: "Alegreya Sans SC, sans-serif",
                color: "#000000",
                fontSize: "1.5rem",
                fontStyle: "bold",
              }}
            >
              {details?.details?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  fontFamily: "Readex Pro, sans-serif",
                  color: "#000000",
                  fontSize: "0.9rem",
                }}
              >
                {details?.details?.description}
              </DialogContentText>
            </DialogContent>
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              fontFamily: "Readex Pro, sans-serif",
              color: "#000000",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TeamDetailsDialogs;
