import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../assets/translations/en/global.json";
import nlTranslation from "../assets/translations/nl/global.json";

// Initialize i18n with the selected language from the cookie or fallback to "en"
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    nl: { translation: nlTranslation },
  },
  interpolation: {
    escapeValue: false,
  },
  lng: sessionStorage.getItem("language") || "en",
  fallbackLng: "en",
});

export default i18n;
