/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import {
  HeroSection,
  ClientsSlider,
  Services,
  HomeDetails,
  Footer,
} from "../components";
const Home = () => {
  return (
    <>
      <HeroSection />
      <ClientsSlider />
      <Services />
      <HomeDetails />
      <Footer />
    </>
  );
};

export default Home;
