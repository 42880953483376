/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { FaLinkedin } from "react-icons/fa";
// import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import {
  MdOutlineMail,
  MdOutlineLocationOn,
  // MdOutlineLocalPhone,
} from "react-icons/md";
import SeQuenXLogo from "../assets/svg/SeQuenXLogo.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const quickLinks = t("footer.quickLinks", { returnObjects: true });
  const policies = t("footer.policies", { returnObjects: true });

  return (
    <footer className="py-4 px-4">
      <div className="container mx-auto flex flex-wrap">
        {/* Column 1 (4 columns on large screens, 2 columns on medium screens, and full width on small screens) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
          <img src={SeQuenXLogo} alt="logo" className="w-1/3" />
          <p className="text-gray-400 my-4 mr-4">{t("footer.description")}</p>
        </div>
        {/* Column 2 (2 columns on large screens, full width on medium and small screens) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
          <h3 className="text-[#455590] text-lg font-semibold">
            {t("footer.quickLinksTitle")}
          </h3>
          <ul className="mt-4">
            {quickLinks.map((link, index) => (
              <li className="mb-2" key={index}>
                <a
                  href={link.link}
                  className="text-gray-400 hover:text-[#455590]"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* Column 3 (1 column on all screen sizes) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
          <h3 className="text-[#455590] text-lg font-semibold">
            {t("footer.policiesTitle")}
          </h3>
          <ul className="mt-4">
            {policies.map((policy, index) => (
              <li className="text-gray-400 mb-2" key={index}>
                <a
                  href={policy.link}
                  className="text-gray-400 hover:text-[#455590]"
                >
                  {policy.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* Column 4 (1 column on all screen sizes) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start">
          <h3 className="text-[#455590] text-lg font-semibold">
            {t("footer.newsletterTitle")}
          </h3>
          <p className="text-gray-400 mt-4 mb-2">
            {t("footer.newsletterText")}
          </p>
          <form
            onSubmit="{handleSubmit}"
            className="mt-2 flex flex-col justify-start"
          >
            <input
              type="email"
              name="email"
              placeholder={t("footer.newsletterPlaceholder")}
              required
              className="border border-[#76AEFF] rounded-md px-2 py-2 w-3/4"
            />
            <button
              type="submit"
              className="bg-[#455590] text-white px-2 py-2 rounded-md mt-2 w-1/3 hover:bg-white hover:text-[#455590] transition duration-300 ease-in-out hover:border-2 hover:border-[#455590]"
            >
              {t("footer.newsletterButton")}
            </button>
          </form>
          {/* Social Media Links */}
          <div className="my-4  flex justify-start">
            <a
              href="https://www.linkedin.com/company/sequenx-b.v./"
              target="_blank"
              className="text-gray-400 hover:text-[#455590] mx-2"
              rel="noreferrer"
            >
              <FaLinkedin size={24} />
            </a>
            {/* <a href="#" className="text-gray-400 hover:text-[#455590] mx-2">
              <FaTwitter size={24} />
            </a>
            <a href="#" className="text-gray-400 hover:text-[#455590] mx-2">
              <FaFacebook size={24} />
            </a>
            <a href="#" className="text-gray-400 hover:text-[#455590] mx-2">
              <FaInstagram size={24} />
            </a> */}
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-wrap">
        {/* Column 1 (4 columns on large screens, 2 columns on medium screens, and full width on small screens) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
          <h3 className=" text-[#455590] font-semibold mb-4">Netherlands</h3>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocationOn size={24} color="#455590" />
            <p className="text-gray-400 w-[90%]">
              Mozartlaan 9, 2215LS, Voorhout, The Netherlands
            </p>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineMail size={24} color="#455590" />
            <p className=" text-gray-400">europe@sequenx.com</p>
          </div>
          {/* <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocalPhone size={24} color="#455590" />
            <p className=" text-gray-400">Location address</p>
          </div> */}
        </div>
        {/* Column 2 (2 columns on large screens, full width on medium and small screens) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
          <h3 className="text-[#455590] text-lg font-semibold mb-4">America</h3>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocationOn size={24} color="#455590" />
            <p className=" text-gray-400 w-[90%] ">
              399 West Trimble Road, San Jose, California 95131, USA{" "}
            </p>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineMail size={24} color="#455590" />
            <p className=" text-gray-400">americas@sequenx.com</p>
          </div>
          {/* <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocalPhone size={24} color="#455590" />
            <p className=" text-gray-400">Location address</p>
          </div> */}
        </div>
        {/* Column 3 (1 column on all screen sizes) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
          <h3 className="text-[#455590] text-lg font-semibold mb-4">India</h3>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocationOn size={24} color="#455590" />
            <p className=" text-gray-400 w-[90%]">
              B-1/H-3, Mohan Co-Operative, Industrial Area, Block-B, New Delhi
              110044.
            </p>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineMail size={24} color="#455590" />
            <p className=" text-gray-400">india@sequenx.com</p>
          </div>
          {/* <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocalPhone size={24} color="#455590" />
            <p className=" text-gray-400">Location address</p>
          </div> */}
        </div>
        {/* Column 4 (1 column on all screen sizes) */}
        <div className="w-full md:w-1/2 lg:w-1/4 text-start">
          <h3 className="text-[#455590] text-lg font-semibold mb-4">
            Pakistan
          </h3>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocationOn size={24} color="#455590" />
            <p className=" text-gray-400 w-[90%]">
              4th Floor, CIS Tech Park, Shahrah-e-Jamhuriat, Sector G-5/2,
              Islamabad 44050.
            </p>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <MdOutlineMail size={24} color="#455590" />
            <p className=" text-gray-400">pakistan@sequenx.com</p>
          </div>
          {/* <div className="flex items-center space-x-2 mb-2">
            <MdOutlineLocalPhone size={24} color="#455590" />
            <p className=" text-gray-400">Location address</p>
          </div> */}
        </div>
      </div>
      <div className="md:justify-center container mx-auto md:flex flex-wrap mt-4">
        <div className="text-center text-gray-400">
          <p>{t("footer.officeHours")}</p>
        </div>
      </div>
      {/* Copyright */}
      <hr className="border-[#76AEFF] my-4" />
      <div className="md:justify-center container mx-auto md:flex flex-wrap">
        <div className="text-center text-gray-400">
          <p>
            &copy; 2016 - {new Date().getFullYear()} {t("footer.copyright")}
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
