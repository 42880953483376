/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useEffect } from "react";
import { SubHeader, InfoSecurityPolicyDetails, Footer } from "../components";

const InfoSecurityPolicy = () => {
  useEffect(() => {
    document.title = "SeQuenX - Information Security Policy";
  }, []);

  return (
    <>
      <SubHeader title={"Information Security Policy"} />
      <div className="my-2"></div>
      <InfoSecurityPolicyDetails />
      <Footer />
    </>
  );
};

export default InfoSecurityPolicy;
