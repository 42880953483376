/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  AboutUs,
  SoftwareDevService,
  QAService,
  SCTService,
  OSSCSService,
  PrivacyPolicy,
  TermsOfUse,
  CookiePolicy,
  InfoSecurityPolicy,
  ContactUs,
  Team,
  Careers,
} from "./pages";
import { Newsbar, Navbar, CookieBanner } from "./components";

function App() {
  return (
    <BrowserRouter>
      <div className="sticky top-0 z-[200]">
        <Newsbar />
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/service/software-dev" element={<SoftwareDevService />} />
        <Route
          path="/service/software-quality-assurance"
          element={<QAService />}
        />
        <Route
          path="/service/software-consultacy-and-training"
          element={<SCTService />}
        />
        <Route
          path="/service/oss-compliance-and-security"
          element={<OSSCSService />}
        />
        <Route path="/policies/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/policies/terms-of-use" element={<TermsOfUse />} />
        <Route path="/policies/cookie-policy" element={<CookiePolicy />} />
        <Route
          path="/policies/information-security"
          element={<InfoSecurityPolicy />}
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
      <CookieBanner />
    </BrowserRouter>
  );
}

export default App;
