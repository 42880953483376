/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";

const SubHeader = ({ title }) => {
  return (
    <div className="bg-subheader py-2 px-4 text-white font-['Alegreya_Sans_SC'] text-4xl">
      <div className="container mx-auto flex items-center justify-left">
        <span className="py-[5rem]">{title}</span>
      </div>
    </div>
  );
};

export default SubHeader;
