/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { QA } from "../assets/svg";
import { useTranslation } from "react-i18next";

const QAServiceDetails = () => {
  const { t } = useTranslation();
  const SQASDesc = t("SQAS.description", { returnObjects: true });

  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full md:w-[50%] max-md:my-4">
          <img
            src={QA}
            alt={t("SCTS.title")}
            className="img-responsive w-[75%] mx-auto"
          />
        </div>
        <div className="w-full md:w-[50%]">
          <div className="text-justify my-auto">
            <p>{SQASDesc[0]}</p>
            <p className="mt-4">{SQASDesc[1]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QAServiceDetails;
