/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { FiMinus, FiPlus } from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

const jobsDetails = [
  {
    title: "Software Engineer (Python)",
    location: "Islamabad, Pakistan",
    description: `<p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: inherit;">Your Responsibilities</span></strong><span style="color: inherit;">:</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">As a&nbsp;</span><span style="color: inherit;">Python Software Engineer at SeQuenX</span><span style="color: inherit;">, you</span><span style="color: inherit;">&nbsp;wil</span><span style="color: inherit;">l:</span></p>
      <ol style="list-style-type: decimal;margin-left:26px;">
          <li style="font-size: 11pt;"><span style="color: inherit;">Collaborate with cross-functional teams to gather requirements, understand project goals, and define technical specifications.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Design, develop, and maintain&nbsp;</span><span style="color: inherit;">high quality&nbsp;</span><span style="color: inherit;">Python-based server-side applications and APIs that power our web and mobile applications.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Implement data models, databases, and data processing mechanisms to support application functionality.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Optimize application performance, scalability, and security through effective coding practices and architectural decisions.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Integrate with front-end components and third-party services to create end-to-end functional solutions.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Collaborate with front-end developers, UI/UX designers, and product managers to ensure seamless application integration.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Perform thorough testing, debugging, and troubleshooting to identify and resolve software defects and issues.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Participate in code reviews, providing constructive feedback and maintaining code quality standards.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Stay up-to-date with industry trends, best practices, and emerging technologies in back-end development.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Contribute to the continuous improvement of development processes, methodologies, and coding standards.</span></li>
      </ol>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">&nbsp;</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: inherit;">Qualifications</span></strong><span style="color: inherit;">:</span></p>
      <ol style="list-style-type: declima;margin-left:26px;">
          <li style="font-size: 11pt;"><span style="color: inherit;">Bachelor&apos;s degree in Computer Science, Software Engineering, or a related field, or equivalent work experience. Good CGPA.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Proficiency in Python programming and a strong understanding of back-end development principles.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Experience with web frameworks such as Django or Flask for building server-side applications.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Familiarity with databases (e.g., PostgreSQL, MySQL, MongoDB) and data modeling concepts.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Strong problem-solving skills and the ability to analyze and optimize code for performance and scalability.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Knowledge of RESTful APIs and integration with front-end components.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Experience with version control systems (e.g., Git) and collaborative development workflows.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Familiarity with cloud platforms (e.g., AWS, Azure) and deployment processes is a plus.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Previous exposure to agile development methodologies and automated testing is beneficial.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Excellent teamwork and communication skills.</span></li>
          <li style="font-size: 11pt;"><span style="color: inherit;">Solid understanding of software development methodologies.</span></li>
      </ol>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">&nbsp;</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: inherit;">How to Apply</span></strong><span style="color: inherit;">:</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Ready to embark on an exciting journey with us? Send your resume and a cover letter to <strong>hr@sequenx.com</strong></span><span style="color: inherit;"><br></span><span style="color: inherit;">Don&apos;t forget to include your GitHub or portfolio link, showcasing your remarkable work!</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Don&apos;t miss this incredible opportunity to shape the future of technology from the heart of Islamabad. Join our team and be part of something extraordinary!</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Let&apos;s code, innovate, and transform the world together!&nbsp;</span><span style="color: inherit;font-size: inherit;">🌐💻🚀</span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Follow us on LinkedIn for more updates and job opportunities! <a href="https://www.linkedin.com/company/sequenx-b.v." target="_blank" rel="noopener noreferrer">[LinkedIn Page]</a></span></p>
      <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">&nbsp;</span></p>`,
  },
  // {
  //   title: "Frontend Developer",
  //   location: "Islamabad, Pakistan",
  //   description: `<p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: inherit;">Your Responsibilities</span></strong><span style="color: inherit;">:</span></p>
  //     <p style="font-size: 11pt;"><span style="color: inherit;"><span style="font-size: 11pt;">As a React.js and Next.js Developer at SeQuenX, you will:</span></p>
  //     <ol style="list-style-type: decimal;margin-left:26px;">
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Collaborate with cross-functional teams to gather requirements, comprehend project objectives, and define technical specifications.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Design, develop, and maintain robust and efficient React.js-based client-side applications that enhance our web and mobile experiences.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Implement responsive and interactive user interfaces, ensuring seamless integration with back-end services.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Work on state management, component lifecycles, and front-end logic to optimize application performance and user experience.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">&nbsp;Collaborate closely with back-end developers, UI/UX designers, and product managers to ensure cohesive end-to-end solutions.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Conduct thorough testing, debugging, and troubleshooting to identify and address software defects and issues.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">&nbsp;Participate actively in code reviews, offering constructive feedback to maintain high code quality standards.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Stay updated with industry trends, best practices, and emerging technologies in front-end development.</span></li>
  //         <li><span style="font-size: 11pt;"><span style="color: inherit;">Contribute to the continuous improvement of development processes, methodologies, and coding standards.</span></li>
  //     </ol>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit; font-size: 14px;">&nbsp;</span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: inherit;">Qualifications</span></strong><span style="color: inherit;">:</span></p>
  //     <ol style="list-style-type: decimal;margin-left:26px;">
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Bachelor&apos;s degree in Computer Science, Software Engineering, or a related field, or equivalent work experience. Good academic performance is a plus.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Proficiency in React.js, Next.js and a strong understanding of front-end development principles.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Experience with popular UI libraries and frameworks such as Material-UI and Ant Design (AntD).</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">In-depth knowledge of state management using Redux and the effective use of React Hooks.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Proficient in styling and theming with CSS frameworks like Tailwind CSS.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Familiarity with modern front-end build tools and package managers (e.g., Webpack, npm).</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Solid understanding of RESTful APIs and their integration with client-side applications.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Experience with version control systems (e.g., Git) and collaborative development workflows.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Familiarity with testing frameworks (e.g., Jest, React Testing Library) is beneficial.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Knowledge of responsive web design and cross-browser compatibility.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Excellent teamwork and communication skills.</span></li>
  //         <li style="font-size: 11pt;"><span style="color: inherit;">Solid understanding of software development methodologies.</span></li>
  //     </ol>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit; font-size: 14px;">&nbsp;</span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><strong><span style="color: inherit;">How to Apply</span></strong><span style="color: inherit;">:</span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Ready to embark on an exciting journey with us? Send your resume and a cover letter to <strong>hr@sequenx.com</strong><br>Don&apos;t forget to include your GitHub or portfolio link, showcasing your remarkable work!</span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Don&apos;t miss this incredible opportunity to shape the future of technology from the heart of Islamabad. Join our team and be part of something extraordinary!</span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Let&apos;s code, innovate, and transform the world together!&nbsp;</span><span style="color: inherit;font-size: inherit;">🌐💻🚀</span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">Follow us on LinkedIn for more updates and job opportunities! <a href="https://www.linkedin.com/company/sequenx-b.v." target="_blank" rel="noopener noreferrer">[LinkedIn Page]</a></span></p>
  //     <p style="text-align: start;color: rgb(66, 66, 66);background-color: rgb(255, 255, 255);font-size: 11pt;"><span style="color: inherit;">&nbsp;</span></p>`,
  // },
];

const JobOpeningsCareers = () => {
  const { t } = useTranslation();

  const [openStates, setOpenStates] = useState(jobsDetails.map(() => false));

  const handleToggle = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  return (
    <div
      className="bg-[#455590] py-4 px-4 text-center text-white"
      id="jobOpenings"
    >
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full ">
          <div className="text-justify my-auto">
            <h1 className="text-3xl font-bold font-['Alegreya_Sans_SC'] my-4">
              {t("careers.jobOpenings.title")}
            </h1>
            <p className="mb-4">{t("careers.jobOpenings.description")}</p>
          </div>
          <div className="text-justify">
            {jobsDetails.map((job, index) => (
              <Card
                key={index}
                sx={{
                  minWidth: 300,
                  border: "1px solid rgba(211,211,211,0.6)",
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
                  borderRadius: 2,
                  marginBottom: 1,
                }}
              >
                <CardHeader
                  title={job.title}
                  subheader={`Location: ${job.location}`}
                  action={
                    <IconButton
                      onClick={() => handleToggle(index)}
                      aria-label="expand"
                      size="small"
                      sx={{ marginTop: "5px" }}
                    >
                      {openStates[index] ? <FiMinus /> : <FiPlus />}
                    </IconButton>
                  }
                  sx={{
                    "& .css-1qvr50w-MuiTypography-root, .css-1g3izzu": {
                      fontFamily: "Alegreya Sans SC",
                      fontWeight: "bold",
                    },
                    "& .css-nrdprl-MuiTypography-root, .css-11is3qa": {
                      fontFamily: "Alegreya Sans SC",
                      fontWeight: "bold",
                    },
                    color: "#455590",
                  }}
                ></CardHeader>
                <div>
                  <Collapse in={openStates[index]} timeout="auto" unmountOnExit>
                    <CardContent
                      sx={{
                        lineHeight: 2,
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: job.description }}
                      />
                    </CardContent>
                  </Collapse>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobOpeningsCareers;
