/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { MdLaptopMac } from "react-icons/md";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const timelineData = [
  {
    year: "2024",
    content: [
      "New Recruitment Partnerships",
      "Launched ComplyVantage ",
      "Revenue > EUR 4 Million",
    ],
  },
  {
    year: "2023",
    content: [
      "Launched Netherlands Pakistan Technology Corridor (NPTC)",
      "MOU with COMSATS & New Office Pakistan",
      "Revenue > EUR 4 Million",
    ],
  },
  {
    year: "2022",
    content: [
      "Hybrid Work Model Implemented",
      "Services Expansion Pakistan",
      "Revenue > EUR 3 Million",
    ],
  },
  {
    year: "2021",
    content: [
      "Services Expansion India",
      "Revenue > EUR 2.5 Million",
      "Expansion of Recruitment Outreach",
    ],
  },
  {
    year: "2020",
    content: [
      "Recruitment Division Setup",
      "Full Remote Deployment COVID-19",
      "Revenue > EUR 2 Million",
    ],
  },
  {
    year: "2019",
    content: [
      "Professional Consulting & (International Placements)",
      "Revenue > EUR 1.5 Million",
      "Expansion of Resource Pool -Code, Deployment, Testing Services",
    ],
  },
  {
    year: "2018",
    content: [
      "Services Expansion into USA",
      "Talent Management & SkillPool Development",
      "Revenue crosses EUR 1 Million",
    ],
  },
  {
    year: "2017",
    content: [
      "Services Expansion: Western Europe",
      "IND Recognized Sponsorship",
      "Revenue > EUR 500K",
    ],
  },
  {
    year: "2016",
    content: ["Founded in August 2016", "Marketing Engagements & Branding"],
  },
];

const OurGrowthStory = () => {
  const { t } = useTranslation();

  return (
    <div className="py-2 px-4">
      <div className="container mx-auto flex flex-col items-center justify-center">
        <div className="w-full text-center py-8">
          <span className="text-[#455590] font-['Alegreya_Sans_SC'] md:text-[2.5rem] text-[2.1rem]">
            {t("aboutUs.storyTitle")}
          </span>
        </div>
        <div className="w-full">
          <Timeline position="alternate">
            {timelineData.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  {item.year}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary" variant="outlined">
                    <MdLaptopMac />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  {item.content.map((text, idx) => (
                    <Typography
                      key={idx}
                      sx={{
                        py: "5px",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        borderRadius: "10px",
                        border: "2px solid gray",
                        px: "10px",
                        mb: "10px",
                      }}
                    >
                      {text}
                    </Typography>
                  ))}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default OurGrowthStory;
