/* eslint-disable jsx-a11y/no-distracting-elements */
/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { FaBullhorn } from "react-icons/fa";

const Newsbar = () => {
  const newsItems = [
    "We are thrilled to announce the launch of the Netherlands Pakistan Technology Corridor (NPTC), a groundbreaking initiative that is set to transform the landscape of technology and innovation.",
    "Breaking news: MOU (Memorandum of Understanding) signed with COMSATS (Commission of Science & Technology in the South).",
    "As part of global expansion we are hiring. Please visit our careers page for more details",
    "SeQuenX is a NEN-4400-1 certified company.",
    "SeQuenX is registered with IND (Immigration & Nationality Directorate) in the Netherlands as a recognized sponsor.",
    "SeQuenX is an OpenChain partner in the Netherlands with the Linux Foundation.",
    "SeQuenX is ISO / IEC 5230 certified company and helps large organizations with security and licensing compliance ",
    "SeQuenX as part of global expansion starts new offices in India and Pakistan",
  ];

  return (
    <div className="bg-[#455590] py-2 px-4 text-white ">
      <div className="container mx-auto flex items-center justify-center">
        <div className="flex items-center">
          <span className="text-[#FF9900]  font-bold mr-2">NewsAlert</span>
          <FaBullhorn className="text-[#FF9900] text-xl mr-2" />
        </div>
        <marquee className="w-full" direction="left" scrollamount="5">
          {newsItems.map((item, index) => (
            <span key={index} className="mr-4">
              {item}
            </span>
          ))}
        </marquee>
      </div>
    </div>
  );
};

export default Newsbar;
