/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { contact_us, Our_vision } from "../assets/svg";

const branchData = [
  {
    title: "Netherlands",
    company: "SeQuenX B.V.",
    address: "Mozartlaan 9, 2215LS, Voorhout, The Netherlands",
    email: "europe@sequenx.com",
    link: "https://www.sequenx.com/contact-us/",
    phoneNo: "",
    imageSrc: contact_us,
    iFrameSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9775.477345915526!2d4.486848816741812!3d52.22759167131634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5c105dff3aaab%3A0x7f744df5cf7878bc!2sMozartlaan%209%2C%202215%20LS%20Voorhout%2C%20Netherlands!5e0!3m2!1sen!2s!4v1692177562280!5m2!1sen!2s",
  },
  {
    title: "America",
    company: "SeQuenX America",
    address: "399 West Trimble Road, San Jose, California 95131, USA",
    link: "https://www.sequenx.com/contact-us/",
    email: "americas@sequenx.com",
    phoneNo: "",
    imageSrc: Our_vision,
    iFrameSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3170.2705994239227!2d-121.9346887!3d37.3834324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcbd80b0e5c1b%3A0xb9a3dadbfd5b3f29!2s399%20W%20Trimble%20Rd%2C%20San%20Jose%2C%20CA%2095131%2C%20USA!5e0!3m2!1sen!2s!4v1696314784253!5m2!1sen!2s",
  },
  {
    title: "India",
    company: "SeQuenX APAC",
    address:
      "B-1/H-3, Mohan Co-Operative, Mathura Road, Industrial Area, Block-B, New Delhi 110044.",
    link: "https://www.sequenx.com/contact-us/",
    email: "india@sequenx.com",
    phoneNo: "",
    imageSrc: Our_vision,
    iFrameSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d219.1095530030938!2d77.29450493131876!3d28.517082778840944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1696315373035!5m2!1sen!2s",
  },
  {
    title: "Pakistan",
    company: "SeQuenX AI",
    address:
      "4th Floor, CIS Tech Park, Shahrah-e-Jamhuriat, Sector G-5/2, Islamabad 44050.",
    link: "https://www.sequenx.com/contact-us/",
    email: "pakistan@sequenx.com",
    phoneNo: "",
    imageSrc: Our_vision,
    iFrameSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13273.774135151687!2d73.0987488!3d33.7233453!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc19e34567c17%3A0xce869e4da9857b0e!2sCIS%20Technology%20Park!5e0!3m2!1sen!2s!4v1696315469794!5m2!1sen!2s",
  },
];

const Branches = () => {
  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      {branchData.map((branch, index) => (
        <div
          key={index}
          className="container mx-auto flex flex-col items-center justify-center my-8 md:flex-row"
        >
          <div className="w-full md:w-[50%]">
            <div className="text-justify mx-auto">
              <h1 className="text-2xl font-bold font-['Alegreya_Sans_SC'] mb-2">
                {branch.title}
              </h1>
              <h1 className="text-2xl font-bold font-['Alegreya_Sans_SC'] mb-2">
                {branch.company}
              </h1>
              <h3>{branch.address}</h3>
              <h3>{branch.email}</h3>
              <h3>{branch.phoneNo}</h3>
              <a href={branch.link} target="_blank" rel="noreferrer">
                <button className="text-lg text-white border-2 border-white px-7 py-2 mt-2 rounded-md font-['Alegreya_Sans_SC'] hover:bg-white hover:text-[#455590] transition duration-300 ease-in-out">
                  Contact
                </button>
              </a>
            </div>
          </div>
          <div className="w-full md:w-[50%] ">
            <b>
              <iframe
                src={branch.iFrameSrc}
                width="80%"
                height="220"
                style={{
                  border: 0,
                  borderRadius: "10px",
                }}
                allowfullscreen=""
                loading="lazy"
                className="md:mx-auto max-md:mt-4"
              ></iframe>
            </b>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Branches;
