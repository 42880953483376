/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { privacy_policy } from "../assets/svg";

const ServiceDetailItem = ({ title, description }) => (
  <li className="hover:text-white leading-8">
    {title}
    {description && (
      <p className="text-sm text-gray-400 font-normal">{description}</p>
    )}
  </li>
);

const PrivacyPolicyDetails = () => {
  const serviceDetails = [
    {
      title:
        "Except through the “Contact” section, SeQuenX does not collect, nor use personal data via the website.",
    },
    {
      title:
        "To analyze data on the use of our website, SeQuenX collects anonymized data.",
    },
    {
      title:
        "SeQuenX has taken appropriate security measures to protect data and to prevent unauthorized access to data. This also applies to the company hosting and administering this website.",
    },
  ];

  const rightsDetails = [
    {
      title:
        "Being informed on which personal data we process and what we are using that data for;",
    },
    {
      title: "Having incorrect data corrected;",
    },
    {
      title: "Request to delete personal data;",
    },
    {
      title: "Revoke your consent to the processing (if applicable);",
    },
    {
      title: "Lodge a complaint with a supervisory authority",
    },
    {
      title: "Object to the use of personal data.",
    },
  ];

  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full md:w-[50%]">
          <div className="text-justify my-auto">
            <p className="leading-8">
              SeQuenX B.V. complies with the requirements of the European
              General Data Protection Regulation (GDPR) and is responsible for
              all data processing on this website. In this privacy statement we
              outline how we process personal data collected through our website
              and how we use cookies:
              <br />
              In short:
            </p>
            <ul className="list-disc list-inside">
              {serviceDetails.map((detail, index) => (
                <ServiceDetailItem
                  key={index}
                  title={detail.title}
                  description={detail.description}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <img
            src={privacy_policy}
            alt="Privacy Policy"
            className="img-responsive w-[60%] mx-auto"
          />
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full">
          <div className="text-justify my-auto">
            <h1 className="text-2xl font-bold mb-4">
              Other Third Party Websites
            </h1>
            <p>
              Our website may contain links to third-party websites. We are not
              responsible for how these third parties operate or treat your
              personal information, so we recommend that you read the privacy
              policies and terms associated with these third-party websites
              carefully.
            </p>
            <h1 className="text-2xl font-bold my-4">
              Inspection and Modification of your Data
            </h1>
            <p>
              You can contact us if you have any questions regarding our privacy
              policy or wish to review, modify or delete your personal data. In
              accordance with the GDPR you have a number of rights. Because the
              website does not store personal data, an appeal to these rights
              will not apply for data collected at the website. For information
              on our corporate privacy policy please contact SeQuenX.
            </p>
          </div>
          <div className="text-justify my-auto">
            <h1 className="text-2xl font-bold my-4">
              Your rights in accordance with the GDPR:
            </h1>
            <ul className="list-disc list-inside">
              {rightsDetails.map((detail, index) => (
                <ServiceDetailItem key={index} title={detail.title} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyDetails;
