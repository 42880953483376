/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React from "react";
import { hiring } from "../assets/svg";
import { useTranslation } from "react-i18next";

const JoinCareersDetails = () => {
  const { t } = useTranslation();
  const reasonsDetails = t("careers.whyUs.reasons", {
    returnObjects: true,
  });

  return (
    <>
      <div className="bg-[#455590] py-8 px-4 text-center text-white">
        <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
          <div className="w-full md:w-[50%]">
            <div className="text-justify my-auto">
              <h1 className="text-3xl font-bold font-['Alegreya_Sans_SC'] my-4">
                {t("careers.joinSeQuenX.title")}
              </h1>
              <p>{t("careers.joinSeQuenX.description")}</p>
              <p className="mb-8 mt-2">
                {t("careers.joinSeQuenX.additionalDescription")}
              </p>
              <a
                href="#jobOpenings"
                className="text-lg text-white border-2 border-white px-6 py-3 rounded-md font-['Alegreya_Sans_SC'] hover:bg-white hover:text-[#455590] transition duration-300 ease-in-out"
              >
                {t("careers.joinSeQuenX.ctaText")}
              </a>
            </div>
          </div>
          <div className="w-full md:w-[50%]">
            <img
              src={hiring}
              alt="Who_We_Logo"
              className="img-responsive w-[75%] mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="py-12 px-4">
        <div className="container mx-auto flex flex-col items-center justify-center rounded-lg shadow-[0px_0px_20px_0px_#00000024] p-8 md:flex-row">
          <div className="w-full">
            <div className="text-justify mx-auto">
              <h1 className="text-xl font-bold text-[#455590] font-['Alegreya_Sans_SC'] mb-2">
                {t("careers.whyUs.title")}
              </h1>
              <h1 className="text-xl font-bold text-[#455590] font-['Alegreya_Sans_SC'] ">
                {t("careers.whyUs.moreReasonsTitle")}
              </h1>
              {reasonsDetails.map((reason, index) => (
                <span key={index}>
                  <h3 className=" font-bold text-[#455590] font-['Alegreya_Sans_SC'] my-2">
                    {reason.title}
                  </h3>
                  <p>{reason.description}</p>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinCareersDetails;
